import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable()
export class DefaultHeadersInterceptor implements HttpInterceptor {

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const newRequest = request.clone({
      headers: request.headers
        .set('content-type', 'application/json')
        .set('Access-Control-Allow-Origin', '*')
        .set('Accept', 'application/json')
        .set('Access-Control-Allow-Methods', 'DELETE, POST, GET, OPTIONS')
        .set(
          'Access-Control-Allow-Headers',
          'Content-Type, Authorization, X-Requested-With'
        ),
    });
    return next.handle(request);
  }
}
