import { PaginationInfo } from '../models/pagination-info';
import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagedResults } from '../models/paged-results';
/*
  We use a fake handler, because the sub class HttpClient,
  requires a handler for the client. This is overwritten later on
*/
class FakeHandler implements HttpHandler {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    throw new Error('Method not implemented.');
  }
}

/*
  Copied from Angular Source
  Types to make life easier when create parameters.
*/
export type OptionParams =
  | HttpParams
  | {
      [param: string]:
        | string
        | number
        | boolean
        | ReadonlyArray<string | number | boolean>;
    };

export type GetOptions = {
  params?: OptionParams;
};

export type GetPaginationOptions = GetOptions & {
  paginationInfo?: PaginationInfo;
};

/**
 * Api Client, an abstraction of the default HttpClient.
 * Custom helper functions
 *
 * @export
 * @class ApiClient
 * @extends {HttpClient}
 */
@Injectable({ providedIn: 'root' })
export class ApiClient extends HttpClient {
  constructor(httpClient: HttpClient) {
    const newHandler = new FakeHandler();
    super(newHandler);
    Object.assign(this, httpClient);
  }

  /**
   * Returns a Paged results
   *
   * @template T
   * @param {string} url
   * @param {GetPaginationOptions} [options]
   * @return {*}  {Observable<PagedResults<T>>}
   * @memberof ApiClient
   */
  public getPagedResults<T>(
    url: string,
    options?: GetPaginationOptions
  ): Observable<PagedResults<T>> {
    if (options && options.paginationInfo) {
      options.params = { ...options.params, ...options.paginationInfo };
      options.paginationInfo = undefined;
    }
    return this.get<PagedResults<T>>(url, options);
  }
}
